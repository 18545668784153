<template>
  <div>
    <!-- Filter Countries -->
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card>
          <div class="card-title">
            <h2>{{ LabelConstant.headingLabelilterStates }}</h2>
          </div>
          <div class="filter-countries">
            <!-- Filter Countries -->
            <div class="justify-end flex w-full flex-wrap mb-4">
              <vs-button
                title="Select all countries"
                color="primary"
                type="border"
                class="small-btn mb-2 w-full sm:w-auto"
                @click="selectAllStates()"
              >
                {{ LabelConstant.buttonLabelSelectAllStates2 }}
              </vs-button>
              <vs-button
                title="Unselect all countries"
                color="primary"
                type="border"
                class="small-btn mb-2 ml-0 sm:ml-4 w-full sm:w-auto"
                @click="unselectAllStates()"
              >
                {{ LabelConstant.buttonLabelUnselectAllStates2 }}
              </vs-button>
            </div>
            <div class="countries-data">
              <h3>{{ LabelConstant.infoLabelFilterStates }}</h3>
              <ul>
                <li v-for="(availableState, i) in availableStates" :key="i">
                  <vs-checkbox :vs-value="availableState.code" v-model="states">{{ availableState.state }}</vs-checkbox>
                </li>
              </ul>
            </div>
            <div class="justify-end flex w-full flex-wrap mt-4 float-left">
              <vs-button
                type="filled"
                color="primary"
                class="mr-0 sm:mr-4 w-full sm:w-auto mt-2"
                @click="updateSelectedStates()"
              >
                {{ LabelConstant.buttonLabelUpdateYourSelectedStates }}
              </vs-button>
              <vs-button
                color="grey"
                type="border"
                class="mt-2 w-full sm:w-auto grey-btn"
                @click="cancelMyChanges()"
              >
                {{ LabelConstant.buttonLabelCancelMyChange }}
              </vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard,
  },
  data() {
    return {
      availableStates: [],
      availableStatesCodes: [],
      states: [],
      previousSelectedStates: [],
      statesLocalList: [
        { code: "AL", state: "Alabama" },
        { code: "AK", state: "Alaska" },
        { code: "AZ", state: "Arizona" },
        { code: "AR", state: "Arkansas" },
        { code: "CA", state: "California" },
        { code: "CO", state: "Colorado" },
        { code: "CT", state: "Connecticut" },
        { code: "DE", state: "Delaware" },
        { code: "DC", state: "District of Columbia" },
        // { code: "DC", state: "District of Columbia" },
        { code: "FL", state: "Florida" },
        { code: "GA", state: "Georgia" },
        { code: "HI", state: "Hawaii" },
        { code: "ID", state: "Idaho" },
        { code: "IL", state: "Illinois" },
        { code: "IN", state: "Indiana" },
        { code: "IA", state: "Iowa" },
        { code: "KS", state: "Kansas" },
        { code: "KY", state: "Kentucky" },
        { code: "LA", state: "Louisiana" },
        { code: "ME", state: "Maine" },
        { code: "MD", state: "Maryland" },
        { code: "MA", state: "Massachusetts" },
        { code: "MI", state: "Michigan" },
        { code: "MN", state: "Minnesota" },
        { code: "MS", state: "Mississippi" },
        { code: "MO", state: "Missouri" },
        { code: "MT", state: "Montana" },
        { code: "NE", state: "Nebraska" },
        { code: "NV", state: "Nevada" },
        { code: "NH", state: "New Hampshire" },
        { code: "NJ", state: "New Jersey" },
        { code: "NM", state: "New Mexico" },
        { code: "NY", state: "New York" },
        { code: "NC", state: "North Carolina" },
        { code: "ND", state: "North Dakota" },
        { code: "OH", state: "Ohio" },
        { code: "OK", state: "Oklahoma" },
        { code: "OR", state: "Oregon" },
        { code: "PA", state: "Pennsylvania" },
        { code: "RI", state: "Rhode Island" },
        { code: "SC", state: "South Carolina" },
        { code: "SD", state: "South Dakota" },
        { code: "TN", state: "Tennessee" },
        { code: "TX", state: "Texas" },
        { code: "UT", state: "Utah" },
        { code: "VT", state: "Vermont" },
        { code: "VI", state: "Virgin Islands" },
        { code: "VA", state: "Virginia" },
        { code: "WA", state: "Washington" },
        { code: "WV", state: "West Virginia" },
        { code: "WI", state: "Wisconsin" },
        { code: "WY", state: "Wyoming" },
      ]
    };
  },
  created() {
    this.getAvailableStates();
  },
  methods: {
    getAvailableStates() {
      this.$vs.loading();
      this.axios
        .get("/ws/ConfigFilterStates/GetAvailableStates")
        .then((response) => {
          let data = response.data;
          this.availableStatesCodes = data;
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.statesLocalList.length; j++) {
              if (data[i] == this.statesLocalList[j].code) {
                this.availableStates.push(this.statesLocalList[j]);
              }
            }
          }
          this.availableStates.sort(this.GetSortOrder("state", "ASC")); //Pass the attribute to be sorted on
          this.getSelectedStates();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getSelectedStates() {
      this.$vs.loading();
      this.axios
        .get("/ws/ConfigFilterStates/GetSelectedStates")
        .then((response) => {
          let data = response.data;
          this.states = data;
          this.previousSelectedStates = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    selectAllStates() {
      this.states = [];
      for (let i = 0; i < this.availableStatesCodes.length; i++) {
        this.states.push(this.availableStatesCodes[i]);
      }
    },
    unselectAllStates() {
      this.states = [];
    },
    cancelMyChanges() {
      this.states = [];
      for (let i = 0; i < this.previousSelectedStates.length; i++) {
        this.states.push(this.previousSelectedStates[i]);
      }
      this.$vs.notify({
        title: "Changes Reverted",
        text: "Your information has been restored to the original state.",
        color: "warning",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
        time: 4000,
      });
    },
    updateSelectedStates() {
      this.$vs.loading();
      this.axios
        .post("/ws/ConfigFilterStates/UpdateSelectedStates",this.states)
        .then(() => {
          this.$vs.notify({
            title: "Success",
            text: "Your information has been updated successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>
